import { createApp } from 'vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import App from './App.vue'
import { version } from '../package.json'

if(process.env.NODE_ENV !== 'development') {
    Bugsnag.start({
        apiKey: 'e963413b9169aa3dbb1ccf465ad67dc0', 
        plugins: [new BugsnagPluginVue()],
        collectUserIp: false,
        appVersion: version,
        onError: function (event) { 
            if(event.originalError instanceof Error) { // console.log(event.originalError.message)
                if(event.originalError.message.includes('Failed to fetch dynamically imported module') || event.originalError.message.includes('not a valid JavaScript MIME type') 
                    || event.originalError.message.includes('Expected a JavaScript module script')) {
                    return false
                }
                if(event.originalError.message.includes("reading 'left'")) {
                    return false
                }
                if(event.originalError.message.includes('refresh token') || event.originalError.message.includes('invalid number of segments') || event.originalError.message.includes('invalid session')) {
                    window.location = `/login?ts=${Date.now()}`
                    return false
                }
            }
        }
    })
}

// import '@fullcalendar/core/vdom' // solves problem with Vite

import kodokuPlugin from './plugins/kodoku'
// import Popper from "vue3-popper"
import { createPinia } from 'pinia'
import localforage from "localforage"
localforage.config({ driver: localforage.INDEXEDDB })
import { EJSON } from 'bson'
import { createRouter, createWebHistory } from 'vue-router'
import { Vue3Mq } from 'vue3-mq'
import i18n from './i18n/index'

import { plugin as VueTippy } from 'vue-tippy'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/shift-away.css'

import './styles/app.scss'
import { useMainStore } from './stores/main'

// import * as Sentry from '@sentry/vue'
// import { BrowserTracing } from "@sentry/tracing"

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {path: '/', name: 'Home', component: () => import('./views/Home.vue')},
        {path: '/app', name: 'InApp', component: () => import('./views/InApp.vue'), children: [
            {path: '', name: 'InAppWrapper', beforeEnter: (to,from,next) => (localStorage.getItem('kdk-auth')) ? next('/app/dashboard') : next({name: 'Login'})},
            {path: 'dashboard', name: 'Dashboard', component: () => import('./views/Dashboard.vue'), children: [
                {path: '', name: 'DashboardOverall', component: () => import('./components/documentation/DashboardOverall.vue')},
                {path: ':clientId', name: 'DashboardClient', component: () => import('./components/schedules/Schedules.vue')},
            ]},
            {path: 'calendar/week/:tenantId?', name: 'CalendarWeek', component: () => import('./components/calendar/Calendar.vue')},
            {path: 'calendar/duties', name: 'CalendarDuties', component: () => import('./components/calendar/Calendar.vue')},
            {path: 'calendar/scheduling', name: 'CalendarScheduling', component: () => import('./components/calendar/Calendar.vue')},
            {path: 'calendar/jobs', name: 'CalendarJobs', component: () => import('./components/calendar/Calendar.vue')},
            {path: 'calendar/fls', name: 'CalendarFls', component: () => import('./components/calendar/Calendar.vue')},
            {path: 'calendar/vacation', name: 'CalendarVacation', component: () => import('./components/calendar/Calendar.vue')},
            {path: 'calendar/today', name: 'CalendarToday', component: () => import('./components/calendar/Calendar.vue')},
            {path: 'calendar/overdue', name: 'CalendarOverdue', component: () => import('./components/calendar/Calendar.vue')},
            {path: 'calendar/done', name: 'CalendarDone', component: () => import('./components/calendar/Calendar.vue')},
            {path: 'calendar/todos/:context?/:listId?', name: 'CalendarTodos', component: () => import('./components/calendar/Calendar.vue')},

            {path: 'todos/:tenantId?/:context?', name: 'Todos', component: () => import('./components/todos/Todos.vue'), /*children: [
                {path: '', name: 'TodosToday', component: () => import('./components/todos/Today.vue')}, 
            ]*/},

            {path: 'messaging/:convId?', name: 'Messaging', component: () => import('./components/messaging/Messaging.vue')},
            {path: 'addressbook/:selected*', name: 'Addressbook', component: () => import('./components/Addressbook.vue')},
            {path: 'storage/:tree*', name: 'Storage', component: () => import('./components/storage/Storage.vue')},
            {path: 'knowledge/:pageId?', name: 'Knowledge', component: () => import('./components/knowledge/Knowledge.vue')},
            {path: 'schedules/:clientId/:tab?', name: 'Schedules', component: () => import('./components/schedules/Schedules.vue'), children: [
                // {path: '', name: 'SchedulesAnalytics', component: () => import('./components/schedules/SchedulesAnalytics.vue')},
                {path: 'network', name: 'SchedulesNetwork', component: () => import('./components/schedules/SchedulesNetwork.vue')},
            ]},
            {path: 'organization', name: 'Organization', component: () => import('./components/organization/Organization.vue'), children: [
                //{path: '', name: 'OrganizationAnalytics', component: () => import('./components/organization/OrganizationAnalytics.vue')},
                {path: '', name: 'OrganizationRoot', redirect: '/app/organization/admin'}, 
                {path: 'admin/:tenantId?', name: 'OrganizationAdmin', component: () => import('./components/organization/OrganizationAdmin.vue')},
                {path: 'jobs/:tenantId?', name: 'OrganizationJobs', component: () => import('./components/organization/OrganizationJobs.vue')},
                {path: 'services/:tenantId?', name: 'OrganizationServices', component: () => import('./components/organization/OrganizationServices.vue')},
                {path: 'templates', name: 'OrganizationTemplates', component: () => import('./components/organization/OrganizationTemplateManager.vue')},
                {path: 'fees', name: 'OrganizationFees', component: () => import('./components/organization/OrganizationFees.vue')},
                {path: 'invoicing/:tenantId?', name: 'OrganizationInvoicing', component: () => import('./components/organization/OrganizationInvoicing.vue')},
                {path: 'cashbooks/:tenantId?', name: 'OrganizationCashbook', component: () => import('./components/organization/OrganizationCashbook.vue')},
            ]},
            // {path: 'admin', name: 'Admin', component: () => import('./components/admin/Admin.vue')},
            {path: 'whiteboard', name: 'Whiteboard', component: () => import('./components/Whiteboard.vue')},
            {path: 'profile', name: 'Profile', component: () => import('./components/MobileProfile.vue')}
        ]},
        {path: '/account', name: 'Account', component: () => import('./views/Account.vue'), children: [
            {path: '', name: 'AccountRoot', redirect: '/account/login'}, 
            {path: 'login', name: 'AccountLogin', component: () => import('./views/account/AccountLogin.vue')},
            /*{path: 'password-reset', name: 'AccountPwReset', component: () => import('./views/account/AccountPasswordReset.vue')},
            {path: 'change-email', name: 'AccountChangeEmail', component: () => import('./views/account/AccountChangeEmail.vue')},
            {path: 'signup', name: 'AccountSignup', component: () => import('./views/account/AccountSignup.vue')},
            {path: 'invite', name: 'AccountInvite', component: () => import('./views/account/AccountInvitation.vue')},*/
            {path: 'onboarding', name: 'AccountOnboarding', component: () => import('./views/account/AccountOnboarding.vue')},
            {path: 'select', name: 'AccountInvalidSelected', component: () => import('./views/account/AccountInvalidSelected.vue')},
            {path: ':type', name: 'AccountBasics', component: () => import('./views/account/AccountBasics.vue')},
        ]},
        {path: '/login', name: 'Login', redirect: '/account/login'}, 
        {path: '/promo/:promoCode', name: 'Promo', component: () => import('./views/Promo.vue')},
        {path: '/404', name: '404', component: () => import('./views/Error.vue'), props: {code: '404'}},
        {path: '/:catchAll(.*)', redirect: '/404'}
    ]
})
router.beforeEach((to, from, next) => { // TODO: Onboarding nach bauen wieder rausnehmen!!
    if (!to.name.includes('Account') && !['Login', 'Home', 'Promo', 'Signup', 'Invite', '404'].includes(to.name) && !localStorage.getItem('kdk-auth')) {
        // 'ChangeEmail', // 'Onboarding', 'Auth', 'AuthConfirm', 'AuthReset' 
        next({ name: 'Login' })
    }
    else next()
})
router.onError((error, to) => { 
    if(error.message.includes('Failed to fetch dynamically imported module') || error.message.includes("not a valid JavaScript MIME type")
        || error.message.includes('Failed to load module script') || error.message.includes('Expected a JavaScript module script')) {
        window.location = `${to.href}?ts=${Date.now()}`
    }
})


async function indexedDbPlugin({ store }) {
    const stored = await localforage.getItem(store.$id + '-state')
    if(stored) store.$patch(EJSON.parse(stored))
    store.$subscribe(() => { localforage.setItem(store.$id + '-state', EJSON.stringify(
        (({ pusher, online, isEditing, modals, dialogs, sidebars, avatarLoadingPool, ...o }) => o)(store.$state)
    )) })
}


const app = createApp(App, {})
const pinia = createPinia()
// pinia.use(indexedDbPlugin)
app.use(pinia)
if(process.env.NODE_ENV !== 'development') {
    const bugsnagVue = Bugsnag.getPlugin('vue')
    app.use(bugsnagVue)
}
app.use(VueTippy, { directive: 'tippy', component: 'tippy', defaultProps: { allowHTML: true, touch: false } })
app.use(Vue3Mq)
app.use(i18n)
app.use(kodokuPlugin)
app.use(router)

// app.provide('user', r?.currentUser);
// app.provide('db', db);


// if(process.env.NODE_ENV !== 'development') {
    /*Sentry.init({
        app, 
        dsn: "https://8de1554b77cf43e78f2b9cff54f1237e@o499678.ingest.sentry.io/5578464", 
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracePropagationTargets: ["kodoku.app", /^\//],
            }),
        ],
        tracesSampleRate: 1.0,
    });*/
// }

app.mount('#app')