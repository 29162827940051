import { ref, reactive, computed } from 'vue'
import { Settings } from 'luxon'
export default {
    install: (app, options) => {
        
        // app.config.globalProperties.$modals = ref([]);
        // app.config.globalProperties.$dialogs = ref([]);
        // app.provide('$modals', app.config.globalProperties.$modals);
        // app.provide('$dialogs', app.config.globalProperties.$dialogs);

        Settings.defaultLocale = 'de';
        Settings.defaultZone = 'Europe/Berlin';


        app.directive('validator', {
            mounted: (el, binding) => { // console.log('mounted directive');
                binding.dir.doValidate = function(event) { // console.log('got val: ', el.value, event, event.target.selectionStart);
                    let chars = el.value.split('');
                    chars.splice(
                        event.target.selectionStart, 
                        event.target.selectionStart !== event.target.selectionEnd ? event.target.selectionEnd : 0, 
                        event.key
                    );
                    if(['Backspace', 'Escape', 'Shift', 'Control', 'Alt', 'Enter', 'Meta', 
                        'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Delete'].includes(event.key)) {
                        // nothing
                    } else if(event.key && event.key.length == 1 && !event.altKey && !event.ctrlKey && !event.metaKey) {
                        if(!(new RegExp(binding.value)).test(chars.join(''))) {
                            console.log('should prevent -> error');
                            event.stopPropagation();
                            event.preventDefault();
                            return false;
                        }
                    }
                }
                el.addEventListener('keydown', binding.dir.doValidate)
            },
            beforeUnmount: (el, binding) => { // console.log('removed');
                el.removeEventListener('keydown', binding.dir.doValidate)
            }
        });


        // app.directive('float-converter', {
        //     mounted: (el, binding) => {

        //     },
        //     beforeUnmount: (el, binding) => {
        //         el.removeEventListener('keydown',)
        //     }
        // })


    }
}