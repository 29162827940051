<template>
    <div class=""> <!-- w-full -->
        <div class="relative flex-1 rounded-md"
            :class="[type === 'tags' || type === 'tenants' ? 'pt-1 pb-0.5 px-1' : type === 'autocomplete' ? 'px-1' : 'py-2 px-3',
                type === 'weekdays' || type === 'monthdays' ? '!p-0' : '', 
                {'border border-gray-300 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600 dark:border-gray-700': border !== false},
                wrapperClasses,
                {'bg-gray-50': disabled || settings.disabled}
            ]">

            <div v-if="inputIcons.left || inputLabels.left" class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <i v-if="inputIcons.left" class="far text-gray-500" :class="inputIcons.left"></i>
                <span v-if="inputLabels.left" class="text-sm text-gray-500/75">{{ inputLabels.left }}</span>
            </div>
            <div v-if="inputIcons.right || inputLabels.right" class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <i v-if="inputIcons.right" class="far text-gray-500" :class="inputIcons.right"></i>
                <span v-if="inputLabels.right" class="text-sm text-gray-500/75">{{ inputLabels.right }}</span>
            </div>

            <label v-if="label" class="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900 dark:bg-[#1d2432] dark:text-gray-300">
                {{ label }}
            </label>

            <input v-if="type === 'text'" type="text" :placeholder="placeholder" v-model="refValue" :class="[classes, {'bg-gray-50': disabled}]" v-bind="attributes" 
                class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm dark:bg-inherit dark:text-gray-200 dark:placeholder:text-gray-200"
                v-validator="applyValidator" :disabled="disabled" /> <!-- @input="onInput" -->

            <input v-if="type === 'time'" type="time" v-model="refValue" :class="classes" v-bind="attributes" 
                class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm dark:bg-inherit dark:text-gray-200 dark:placeholder:text-gray-200"
                :disabled="disabled" />

            <input v-if="type === 'password'" type="password" :placeholder="placeholder" v-model="refValue" :class="classes" 
                class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm dark:bg-inherit dark:text-gray-200 dark:placeholder:text-gray-200"
                v-validator="applyValidator" :disabled="disabled" />

            <textarea v-if="type === 'textarea'" :placeholder="placeholder" v-model="refValue" v-bind="settings" :class="[classes, {'bg-gray-50': disabled}]" 
                class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm resize-none dark:bg-inherit dark:text-gray-200 dark:placeholder:text-gray-200"
                v-validator="applyValidator" :disabled="disabled"></textarea> <!-- @input="onInput" -->

            <DatePicker v-if="type === 'date'" v-model="refValue" v-bind="settings" :class="classes" @pickerToggled="emit('pickerToggled', $event)"></DatePicker> <!-- @update:modelValue="onInput" -->


            <select v-if="type === 'select'" v-model="refValue" :class="[classes, {'bg-gray-50': disabled}]" :disabled="disabled" 
                class="block w-full p-0 pr-6 rounded-md border-0 text-base focus:border-0 focus:outline-none focus:ring-0 
                    sm:text-sm bg-[right_0rem_center] dark:bg-inherit dark:placeholder:text-gray-300 dark:text-gray-300"> <!-- @change="onInput" -->
                <option v-for="o in options" :value="o.value" :key="`form-input-select-${label}-${o.text}`">{{ o.text }}</option>
            </select>



            <div v-if="type === 'geo'" class="flex items-center group">
                <input type="text" :placeholder="placeholder" :value="formattedAddress" 
                    ref="placesInput" :class="classes" 
                    class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm
                        dark:bg-inherit dark:text-gray-200 dark:placeholder:text-gray-200" />
                    <!-- @input="onInput" -->
                <button class="text-sm" :class="[refValue?.preferred ? 'block' : 'hidden group-hover:inline', {'px-2': !addressMandatory}]" 
                    @click="setPreferred" v-if="!buttonsHidden">
                    <i class="fa-star fa-fw" :class="refValue?.preferred ? 'fas text-orange-400' : 'fal'" @mouseover="fillStar" @mouseleave="unfillStar"></i>
                </button>
                <DropDown v-if="!addressMandatory" :items="[{value: 'work', text: 'Arbeit'}, {value: 'private', text: 'Privat'}]" 
                    class="text-sm" classes="flex" @select="refValue.type = $event.value">
                    <button class="flex items-center">
                        <span class="pr-2 text-gray-600">{{ strings[refValue.type] }}</span>
                        <i class="fal fa-fw pr-1" :class="'fa-map-marker-alt'"></i>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                        </svg>
                    </button>
                </DropDown>
                <DropDown v-if="!addressMandatory" class="text-sm" classes="flex" @select="addOrRemove" 
                    :items="[
                        ...(hideRemoveButtons ? [] : [{value: 'remove', text: 'Adresse entfernen'}]), 
                        {value: 'work', text: 'Arbeit'}, {value: 'private', text: 'Privat'}
                    ]">
                    <button class="text-sm ml-1">
                        <i class="fal fa-ellipsis-v fa-fw"></i>
                    </button>
                </DropDown>
            </div>


            <div v-if="type === 'phone'" class="flex items-center group">
                <input type="text" :placeholder="placeholder" v-model="refValue.number" :class="classes" 
                    class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm
                        dark:bg-inherit dark:text-gray-200 dark:placeholder:text-gray-200" /> <!-- @input="onInput" -->
                <button class="px-2 text-sm" :class="refValue?.preferred ? 'block' : 'hidden group-hover:inline'" @click="setPreferred" v-if="!buttonsHidden">
                    <i class="fa-star fa-fw" :class="refValue?.preferred ? 'fas text-orange-400' : 'fal'" @mouseover="fillStar" @mouseleave="unfillStar"></i>
                </button>
                <DropDown :items="Object.keys(strings).map(k => ({text: strings[k], value: k}))" class="text-sm" classes="flex" 
                    @select="refValue.type = $event.value" v-if="!buttonsHidden">
                    <button class="flex items-center">
                        <span class="pr-2 text-gray-600">{{ strings[refValue.type] }}</span>
                        <i class="fal fa-fw pr-1" :class="icons[refValue.type]"></i>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                        </svg>
                    </button>
                </DropDown>
                <DropDown v-if="!buttonsHidden" :items="[
                    ...(hideRemoveButtons ? [] : [{value: 'remove', text: 'Nummer entfernen'}]), 
                    ...Object.keys(strings).map(k => ({text: `+ ${strings[k]}`, value: k}))
                ]" 
                    class="text-sm" classes="flex" @select="addOrRemove">
                    <button class="text-sm ml-1">
                        <i class="fal fa-ellipsis-v fa-fw"></i>
                    </button>
                </DropDown>
            </div>


            <div v-if="type === 'email'" class="flex items-center group">
                <input type="email" :placeholder="placeholder" v-model="refValue.address" :class="classes" 
                    class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm
                        dark:bg-inherit dark:text-gray-200 dark:placeholder:text-gray-200" /> <!-- @input="onInput" -->
                <button class="px-2 text-sm" :class="refValue?.preferred ? 'block' : 'hidden group-hover:inline'" @click="setPreferred" v-if="!buttonsHidden">
                    <i class="fa-star fa-fw" :class="refValue?.preferred ? 'fas text-orange-400' : 'fal'" @mouseover="fillStar" @mouseleave="unfillStar"></i>
                </button>
                <DropDown :items="[{value: 'work', text: strings['work']}, {value: 'private', text: strings['private']}]" 
                    class="text-sm" classes="flex" @select="refValue.type = $event.value" v-if="!buttonsHidden">
                    <button class="flex items-center">
                        <span class="pr-2 text-gray-600">{{ strings[refValue.type] }}</span>
                        <i class="fal fa-fw pr-2" :class="icons['email']"></i>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                        </svg>
                    </button>
                </DropDown>
                <DropDown v-if="!buttonsHidden" :items="[
                    ...(hideRemoveButtons ? [] : [{value: 'remove', text: 'E-Mail entfernen'}]), 
                    {value: 'work', text: '+ Arbeit'}, {value: 'private', text: '+ Privat'}
                ]" class="text-sm" classes="flex" @select="addOrRemove">
                    <button class="text-sm ml-1">
                        <i class="fal fa-ellipsis-v fa-fw"></i>
                    </button>
                </DropDown>
            </div>


            <TagInput v-if="type === 'tags'" v-model="refValue" v-bind="settings" :class="[classes, {'bg-gray-50': settings.disabled}]"></TagInput> <!-- @update:modelValue="onInput" -->

            <TenantInput v-if="type === 'tenants'" v-model="refValue" v-bind="settings" :class="classes"></TenantInput>


            <button v-if="type === 'addressbook'" class="truncate" :class="classes" @click="openAddressbookPicker(props.settings)">
                <slot></slot>
            </button>


            <AutocompleteInput v-if="type === 'autocomplete'" v-model="refValue" v-bind="settings" :class="classes" @select="autocompleteSelect">
                <template #default="itemProps">
                    <slot :item="itemProps.item"></slot>
                </template>
            </AutocompleteInput>


            <div v-if="type === 'autocomplete-date-time'" :class="classes" class="flex items-center">
                <DatePicker v-if="refValue?.freq" v-model="refValue.dtstart" labelFormat="'ab' dd.MM.yy" class="w-[130px] text-left"></DatePicker>
                <AutocompleteDateTimeInput v-model="refValue" v-bind="settings" class="w-full border-0" :class="{ 'px-4': refValue?.freq }"
                    @select="autocompleteDateTimeSelect"></AutocompleteDateTimeInput>
                <DatePicker v-if="refValue?.freq" v-model="refValue.until" :nullLabel="'&infin;'" labelFormat="'bis' dd.MM.yy" 
                    @update:modelValue="emit('select', refValue)" :isNullAllowed="true" nullLabelInPicker="unbegrenzt" 
                    :endDayInclusive="true" class="w-[140px] text-right"></DatePicker>
            </div>
            <!-- <small v-if="type === 'autocomplete-date-time'">{{ refValue }}</small> -->


            <div v-if="type === 'weekdays'" class="overflow-hidden flex text-sm justify-around px-1" :class="classes"> <!-- rounded -->
                <span v-for="n in Array(7).keys()" :key="`form-input-tpl-weekday-${n}`" class="w-8 text-center p-1 cursor-pointer" @click="toggleWeekday(weekdaysIndexZero ? n : n+1)"
                    :class="[refValue.includes(weekdaysIndexZero ? n : n+1) ? 'font-semibold' : disabled ? 'text-gray-400/80' : 'text-gray-400/80 hover:text-gray-700']">
                    {{ DateTime.local().startOf('week').plus({days: n}).toFormat('ccc') }}
                </span>
            </div>

            <div v-if="type === 'monthdays'" class="rounded overflow-hidden flex text-sm">
                <span v-for="n in [...Array(31).keys()].filter(k => parseInt(k) % 2 == 0)" :key="`form-input-tpl-monthday-${n}`" 
                    class="flex-1 text-center py-2 cursor-pointer"
                    :class="[refValue.includes(weekdaysIndexZero ? n : n+1) ? 'bg-gray-700 text-white hover:bg-gray-800' : 'hover:bg-gray-100']" @click="toggleWeekday(weekdaysIndexZero ? n : n+1)">
                    {{ weekdaysIndexZero ? n : n+1 }}
                </span>
            </div>


        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { DateTime } from 'luxon'
import { EJSON } from 'bson'
import DatePicker from '/src/components/DatePicker.vue'
import DropDown from '/src/components/DropDown.vue'
import TagInput from '/src/components/TagInput.vue'
import TenantInput from '/src/components/TenantInput.vue'
import AutocompleteInput from '/src/components/AutocompleteInput.vue'
import AutocompleteDateTimeInput from '/src/components/AutocompleteDateTimeInput.vue'
import { useMainStore } from '../stores/main'
const mainStore = useMainStore()
const props = defineProps({
    modelValue: [String, Number, Array, Object, Date, Boolean],
    type: {type: String, default: 'text'},
    label: {type: String, default: ''},
    placeholder: {type: String, default: ''},
    disabled: {type: Boolean, default: false},
    border: {type: Boolean, default: true},
    classes: {type: String, default: ''},
    wrapperClasses: {type: String, default: ''},
    options: {type: Array, default: []},
    settings: {type: Object, default: {}},
    attributes: {type: Object, default: {}}, 
    buttonsHidden: {type: Boolean, default: false},
    applyFormatter: {type: String, default: ''},
    applyValidator: {type: String, default: ''},
    inputIcons: {type: Object, default: {left: '', right: ''}},
    inputLabels: {type: Object, default: {left: '', right: ''}},
    addressMandatory: {type: Boolean, default: false},
    hideRemoveButtons: {type: Boolean, default: false},
    weekdaysIndexZero: {type: Boolean, default: false}
})
const emit = defineEmits(['update:modelValue', 'setPreferred', 'addOrRemove', 'select', 'pickerToggled'])
// const refValue = ref(props.modelValue)
const refValue = computed({
    set(val) { // console.log('SET', val);
        if(props.applyFormatter) {
            emit('update:modelValue', formatters[props.applyFormatter].set(val) )
        } else {
            emit('update:modelValue', val)
        }
    },
    get() { // console.log('GET', props.modelValue);
        return props.applyFormatter ? formatters[props.applyFormatter].get(props.modelValue) : props.modelValue
    }
})

let EUR = new Intl.NumberFormat('de-DE', { style: 'decimal', maximumFractionDigits: 2 });
const formatters = {
    parseInteger: {
        get: (val) => `${val}`,
        set: (val) => Number(val)
    },
    floatPercentBaseZero: {
        get: (val) => (Number((parseFloat(val || 0)*100).toPrecision(8))).toString().replace('.', ','),
        set: (val) => (Number((parseFloat(val.replace(',', '.') || 0)/100).toPrecision(8)))
    },
    floatPercentBaseOne: {
        get: (val) => (Number((parseFloat((val-1) || 0)*100).toPrecision(8))).toString().replace('.', ','),
        set: (val) => Number(((Number((parseFloat(val.replace(',', '.') || 0)/100).toPrecision(8))) + 1).toPrecision(8))
    },
    germanFloat: {
        get: (val) => (val||'0').toString().replace('.', ','),
        set: (val) => Number(parseFloat((['+','-'].includes(val) ? `${val}0`: val || 0).toString().replace(',', '.')).toPrecision(8)) || 0
    },
    germanFloatEmptyStringAllowed: {
        get: (val) => (val||'').toString().replace('.', ','),
        set: (val) => Number(parseFloat((['+','-'].includes(val) ? `${val}0`: val || 0).toString().replace(',', '.')).toPrecision(8)) || ''
    },
    germanCurrency: {
        get: (val) => (val||'0').toString().replace('.', ','),
        set: (val) => Number(parseFloat((['+','-'].includes(val) ? `${val}0`: val || 0).toString().replace(',', '.')).toPrecision(8)) || 0
    },
    uppercase: {
        get: (val) => val.toString().toUpperCase(),
        set: (val) => val.toString().toUpperCase()
    },
    newLineHtmlBreakConversion: {
        get: (val) => val.toString().replaceAll('<br>', "\r\n"),
        set: (val) => val.toString().replaceAll("\n", '<br>')
    }
}

const placesInput = ref()
const formattedAddress = computed(() => {
    return refValue.value?.formatted || `${refValue.value?.street||''} ${refValue.value?.hnr||''} ${refValue.value?.zip||''} ${refValue.value?.city||''}`.trim() || ''
})

const icons = {
    work: 'fa-phone-alt',
    private: 'fa-phone-rotary',
    mobile: 'fa-mobile-alt',
    fax: 'fa-fax',
    email: 'fa-envelope'
}
const strings = {
    work: 'Arbeit', private: 'Privat', mobile: 'Mobil',
    fax: 'Fax', // email: 'E-Mail'
}

const fillStar = ($event) => {
    if(!refValue.value?.preferred) {
        $event.target.classList.remove('fal')
        $event.target.classList.add('fas')
    }
}
const unfillStar = ($event) => {
    if(!refValue.value?.preferred) {
        $event.target.classList.remove('fas')
        $event.target.classList.add('fal')
    }
}

const toggleWeekday = (d) => {
    if(props.settings.limit && refValue.value.length == props.settings.limit && !refValue.value.includes(d)) {
        mainStore.openDialog({title: 'Achtung', message: 'Es sind gesetzlich nur 6 Werktage pro Woche erlaubt.', actions: [{type: 'sky', label: 'Ok'}]})
        return
    }
    if(!props.disabled) {
        if(refValue.value.includes(d)) refValue.value = refValue.value.filter(n => n !== d)
        else {
            if(props.settings.maxWeekdays && [...refValue.value, d].length > props.settings.maxWeekdays) {
                mainStore.openDialog({title: 'Achtung', message: props.settings.maxWeekdaysMessage, actions: [{type: 'sky', label: 'Ok'}]})
                return
            } else {
                refValue.value = [...refValue.value, d]
            }
        }
    }
}

const autocompleteSelect = ($event) => {
    emit('select', $event)
}

const autocompleteDateTimeSelect = ($event) => {
    emit('select', $event)
}

const onInput = (e) => {
    emit('update:modelValue', refValue.value)
}

const setPreferred = () => {
    emit('setPreferred')
}
const addOrRemove = ($event) => {
    emit('addOrRemove', $event.value)
}

const openAddressbookPicker = () => {
    mainStore.openModal({
        component: 'components/Addressbook', props: {
            // networkFixedSlot: fixedSlot // context: 'network', inNetworkOf: props.person._id,
            context: 'input',
            category: props.settings.category || '',
            typeFilter: props.settings.typeFilter || ''
        }, closeX: false, closeBackdrop: true,
        minWidth: '420px', maxWidth: '420px', minHeight: '600px', maxHeight: '650px', 
        events: { itemSelected: (item) => {
            refValue.value = item._id
        } }
    })
}

onMounted(() => {
    if(props.type === 'geo') {
        const options = {
            componentRestrictions: { country: "de" },
            fields: [
                "formatted_address", 
                "address_components", 
                "geometry", 
                "icon", 
                "name"
            ],
            strictBounds: false,
            types: ["address"],
        };
        const autocomplete = new google.maps.places.Autocomplete(placesInput.value, options);
        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            if(!place || !place.address_components) {
                mainStore.openDialog({ title: 'Achtung', message: 'Bitte wähle deine Adresse aus der Liste aus.', actions: [{type: 'sky', label: 'Okay'}]})
                return;
            }
            let addr = EJSON.parse(EJSON.stringify(refValue.value))
            addr.formatted = place.formatted_address
            addr.street = place.address_components.find(c => c.types.includes('route'))?.long_name || ''
            addr.hnr = place.address_components.find(c => c.types.includes('street_number'))?.long_name || ''
            addr.zip = place.address_components.find(c => c.types.includes('postal_code'))?.long_name || ''
            addr.city = place.address_components.find(c => c.types.includes('locality'))?.long_name || ''
            addr.country = place.address_components.find(c => c.types.includes('country'))?.short_name || ''
            addr.geo = {type: 'Point', coordinates: [place.geometry.location.lng(), place.geometry.location.lat()]}
            emit('update:modelValue', addr)
        })
    }
})

</script>