<template>
    <div class="fixed inset-0" :show="open" :class="[z, backdrop ? '' : 'pointer-events-none']"> <!-- overflow-y-auto -->
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            :class="open && backdrop ? 
                'ease-out duration-300 opacity-100' 
                : 'ease-in duration-200 opacity-0'
                " />
            <div class="absolute top-0 h-full overflow-y-auto transform transition ease-in-out duration-300 shadow-lg sidebarWrapper" :class="[
                    position === 'right' ? 'right-0' : 'left-0', 
                    open ? 'translate-x-0' : (position === 'right' ? 'translate-x-full' : '-translate-x-full'), 
                    mq.xs ? 'w-[100%]' : width ? width : 'w-[320px]', 
                    z, 
                    mq.xs ? '' : 'rounded-l-lg'
                ]">
                <div class="w-full h-full">
                    <div class="flex h-full flex-col overflow-y-auto bg-white pointer-events-auto"
                        :class="{'p-6': headline || closeX}">

                        <h2 v-if="headline || closeX" class="text-lg font-medium text-gray-900 text-left" :class="headline ? 'mb-6' : 'mb-2'">
                            <span v-if="headline">{{ headline }}</span>
                            <div class="ml-3 flex h-7 items-center float-right" v-if="closeX">
                                <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 
                                    focus:outline-none focus:ring-0" @click="closeModal">
                                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                        </h2>
                        <div class="text-left">
                            <component :is="comp" :key="`comp-${key}`" v-bind="props.props" v-on="events" @close="closeModal"></component>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, defineAsyncComponent, h } from 'vue'
import { useMainStore } from '/src/stores/main'
import { useMq } from "vue3-mq"
const mq = useMq()
const props = defineProps({
    component: {type: String, default: ''}, // [Object, String]
    props: {type: Object, default: {}},
    events: {type: Object, default: {}},
    key: {type: String, default: ''},
    headline: {type: String, default: ''},
    width: {type: String, default: ''},
    position: {type: String, default: 'right'}, 
    classes: {type: String, default: ''},
    closeX: {type: Boolean, default: true},
    backdrop: {type: Boolean, default: true},
    z: {type: String, default: 'z-20'}
})
const open = ref(false)
const mainStore = useMainStore()
const MODULES = import.meta.glob('../**/*.vue');

var comp = defineAsyncComponent({
    loader: () => MODULES[`../${props.component.replace('components/', '')}.vue`.replace('..', '.')](),
    loadingComponent: h('div', {class: 'h-full w-full flex items-center justify-center text-gray-500 p-12'}, [h('i', {class: 'far fa-spinner-third fa-spin fa-fw'})]),
    errorComponent: h('div', {class: 'h-full w-full flex flex-col items-center justify-center p-4'}, [
        h('div', { class: 'text-center text-sm text-gray-500 leading-6' }, [
            h('strong', {class: 'block text-lg'}, `${navigator.onLine ? 'Eine neue Version ist verfügbar.' : 'Keine Internetverbindung.'}`),
            h('span', {class: 'block'}, 'Bitte aktualisiere die Ansicht und versuche es erneut.')
        ]),
        h('button', {
            class: 'bg-kd hover:bg-kd-hover text-white rounded-md px-4 py-2 mt-4 text-sm',
            onclick: () => window.location.href = `${window.location.origin}${window.location.pathname}?ts=${Date.now()}`
        }, ['Jetzt aktualisieren'])
    ]),
    onError(error, retry, fail, attempts) { 
        fail()
    }
})
onMounted(() => {
    setTimeout(() => { open.value = true }, 100);
});
const closeModal = () => {
    open.value = false;
    setTimeout(() => { mainStore.sidebars.splice(-1) }, 500);
}
</script>