<template>
    <div class="relative inline-block text-left" :class="wrapperClasses">
    <!-- <div> -->
        <div v-if="trigger === 'click'" class="flex-1 cursor-pointer" @click="openDropdown" :class="triggerClasses" ref="dropdownTrigger">
            <slot></slot>
        </div>
        <div v-else-if="trigger === 'hover'" class="flex-1 cursor-pointer" @mouseenter="openDropdown" @mouseleave="closeDropdown" :class="triggerClasses" ref="dropdownTrigger">
            <slot></slot>
        </div>
    <!-- </div> -->

        <teleport to="body">
            <transition 
                enter-active-class="transition ease-out duration-300" 
                enter-from-class="transform opacity-0 scale-95" 
                enter-to-class="transform opacity-100 scale-100" 
                leave-active-class="transition ease-in duration-200" 
                leave-from-class="transform opacity-100 scale-100" 
                leave-to-class="transform opacity-0 scale-95">
                <!-- <template #content="{ hide }"> -->
                    <div v-if="dropdownOpen" ref="pickerDropdown" 
                        class="fixed mt-2 z-50 rounded-md shadow-lg flex flex-col overflow-hidden bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        :class="[maxWidth ? maxWidth : 'w-64', maxHeight ? maxHeight : 'max-h-52', classes, origin ? origin : 'origin-top-right']">
                    
                        <slot name="header" :close="closePickerOutside"></slot>

                        <div v-if="!custom" class="py-1 overflow-y-auto">
                            <a v-for="(item,i) in items" :key="`dropdown-item-${i}`" @click="select(item)"
                                :class="[item.textClasses || 'text-gray-700 hover:text-gray-900', 'block px-4 py-2 text-sm cursor-pointer hover:bg-gray-100']">
                                <span v-if="item.indent">{{ '&nbsp;&nbsp;&nbsp;&nbsp;'.repeat(item.indent) }}</span>
                                <i v-if="item.icon" :class="item.icon" class="mr-1"></i>
                                {{item.text}}
                            </a>
                        </div>
                        <div v-else class="divide-y divide-gray-100 overflow-y-auto" :class="listClasses">
                            <div v-for="(item,i) in items" :key="`custom-dropdown-item-${i}`">
                                <slot name="item" :item="item" :close="closePickerOutside"></slot>
                            </div>
                        </div>

                        <slot name="footer" :close="closePickerOutside"></slot>

                    </div>
                <!-- </template> -->
            </transition>
        </teleport>
    </div>
</template>
<!-- :appendTo="docBody" trigger="click" theme="transparent" :arrow="false" interactive animation="shift-away" :placement="props.placement || 'top'" -->
<script setup>
import { ref, watch } from 'vue'
const props = defineProps({
    items: {type: Array, default: []},
    maxWidth: {type: String, default: ''},
    maxHeight: {type: String, default: ''},
    custom: {type: Boolean, default: false},
    placement: {type: String, default: ''}, 
    classes: {type: String, default: ''},
    wrapperClasses: {type: String, default: ''},
    triggerClasses: {type: String, default: ''},
    listClasses: {type: String, default: ''},
    offset: {type: Object, default: {x: 0, y: 0}},
    origin: {type: String, default: 'origin-top-right'},
    temporarilyFixed: {type: Boolean, default: false},
    trigger: {type: String, default: 'click'},
    disabled: {type: Boolean, default: false}
})
const emit = defineEmits(['select'])

const dropdownTrigger = ref()
const pickerDropdown = ref()
const dropdownOpen = ref(false)
const dropdownPosition = ref({top: 0, left: 0})
const docBody = document.body

watch(dropdownOpen, async(newState, oldState) => {
    setTimeout(() => {
        if(newState) {
            document.body.addEventListener('click', closePickerOutside)
            // pickerDropdown.value.style.top = `calc(${pickerDropdown.value.getBoundingClientRect().top 
            //     - (pickerDropdown.value.parentNode.closest('.overflow-y-auto')?.getBoundingClientRect()?.top || 0) 
            //     - parseInt(getComputedStyle(pickerDropdown.value).marginTop.replace('px', ''))}px - 0.5rem)`;

            // pickerDropdown.value.style['top'] = `calc(
            //     ${pickerDropdown.value.getBoundingClientRect().top}px
            //      - ${((pickerDropdown.value.parentNode.closest('.overflow-y-auto')?.getBoundingClientRect()?.top || 0) + 
            //      (pickerDropdown.value.parentNode.closest('.overflow-y-auto')?.scrollTop || 0))}px - 1rem
            // )`;


            pickerDropdown.value.style['top'] = `${dropdownPosition.value.top + 25 + (props.offset.y || 0)}px`

            let triggerRect = dropdownTrigger.value.getBoundingClientRect();
            if(props.origin.includes('right')) {
                pickerDropdown.value.style['right'] = `calc(${(window.innerWidth - triggerRect.left - triggerRect.width
                    - pickerDropdown.value.parentNode.closest('.overflow-y-auto')?.getBoundingClientRect()?.left || 0
                    ) + (props.offset.x || 0)}px)`;
            } else {
                pickerDropdown.value.style['left'] = `calc(${(triggerRect.left 
                    - pickerDropdown.value.parentNode.closest('.overflow-y-auto')?.getBoundingClientRect()?.left || 0
                    ) + (props.offset.x || 0)}px)`;
            }
        } else {
            document.body.removeEventListener('click', closePickerOutside)
        }
    }, 1);
})

const openDropdown = (e) => {
    if(!props.disabled) {
        //dropdownPosition.value = e.target.getBoundingClientRect()
        dropdownPosition.value = dropdownTrigger.value.getBoundingClientRect()
        dropdownOpen.value = true
    }
}
const closeDropdown = (e) => {
    dropdownOpen.value = false
}
const closePickerOutside = (event) => {
    if(!pickerDropdown.value?.contains(event?.target) && !props.temporarilyFixed) {
        dropdownOpen.value = false;
    }
}
const select = (item) => { // console.log(item);
    emit('select', item)
    dropdownOpen.value = false
}
defineExpose({
    openDropdown,
    closeDropdown
})
</script>